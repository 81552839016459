function setCookie(name: string, value: string, days: number): void {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
}

function getCookie(name: string): string | null {
  const cookieArray = document.cookie.split('; ');
  for (const cookie of cookieArray) {
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName === name) {
      if (!cookieValue)
        throw new Error(`value for cookieName: ${cookieName} is empty`);

      return decodeURIComponent(cookieValue);
    }
  }
  return null;
}

const CONSENT_COOKIE_NAME = 'userConsent';

export const useCookieConsent = () => {
  const $q = useQuasar();

  // Метод для показа уведомления о cookies
  const showCookieNotification = (): void => {
    if (getCookie(CONSENT_COOKIE_NAME)) {
      console.log('Ответ на использование cookie уже получен');
      return;
    }

    $q.notify({
      icon: 'cookie',
      message: 'Мы используем cookies для улучшения вашего опыта',
      caption:
        'Продолжая использовать сайт, вы соглашаетесь с нашей <a href="/Политика_конфиденциальности.pdf" target="_blank" style="color: white;">политикой конфиденциальности</a>',
      html: true,
      actions: [
        // {
        //   label: 'Отказаться',
        //   // color: 'negative',
        //   color: 'white',
        //   handler: () => {
        //     setCookie(CONSENT_COOKIE_NAME, 'declined', 365); // Сохраняем отказ на год
        //   },
        // },
        {
          label: 'OK',
          // color: 'positive',
          color: 'white',
          handler: () => {
            setCookie(CONSENT_COOKIE_NAME, 'accepted', 365); // Сохраняем согласие на год
          },
        },
      ],
      timeout: 0, // Уведомление не исчезает автоматически
      position: 'bottom', // Позиция уведомления
      color: 'primary',
    });
  };

  // // Проверка наличия согласия при монтировании компонента
  // onMounted(() => {
  //   if (!getCookie(CONSENT_COOKIE_NAME)) {
  //     showCookieNotification();
  //   }
  // });

  return {
    showCookieNotification,
  };
};
