<script setup lang="ts">
const $q = useQuasar();

// import ymSend from 'src/ymSend';

const eventBus = inject('bus') as EventBus;

const formDataService = useFeathersService('form-data');

const formRef = ref();
const nameRef = ref();
const phoneRef = ref();
const emailRef = ref();
//
const isShow = ref();
// const otherFields = ref();
const isFormValid = ref(false);

const formData = ref();

// const bookTripTmpDev = () => ({
//   title: '',
//   section: '',
//   name: 'Иван Н',
//   phone: '89811426842',
//   email: 'test@mail.ru',
//   adultsAmount: 2,
//   childrenAmount: 1,
//   proposeDate: '2024/02/14',
//   comment: 'Хочу в баньку!',
// });

type FormType = 'work-with-as' | 'merch-order';

const getFormDataTmp = (type: FormType, section: string) => {
  const baseFields = {
    type,
    section,
  };

  // if (!data?.title) throw new Error('Merch title is required');

  if (type === 'work-with-as') {
    return {
      ...baseFields,

      title: 'Привет!',
      // data: {
      //   ...data,
      //   title: data.title,
      // },
    };
  }

  // if (type === 'merch-order') {
  //   return {
  //     ...baseFields,
  //     title: 'Заказать',
  //     data,
  //   };
  // }

  throw new Error('Not alloved tmp type');
};

// const isShowField = (fieldName: string) =>
//   otherFields.value?.includes(fieldName);

eventBus.on('OPEN_FORM_DIALOG', ([type, section]: [FormType, string]) => {
  formData.value = formDataService.new(getFormDataTmp(type, section));

  // if (type === 'book-trip') {
  //   otherFields.value = [
  //     'adultsAmount',
  //     'childrenAmount',
  //     'proposeDate',
  //     'comment',
  //   ];
  // }

  // if (type === 'merch-order') {
  //   otherFields.value = ['comment'];
  // }

  // if (type === 'order') {
  //   formData.title = 'Оформить заказ';
  //   // btnLabel.value = 'Пригласить на тендер';
  // }

  // if (type === 'callback') {
  //   title.value = 'Заказать звонок';
  //   // btnLabel.value = 'Пригласить на тендер';
  // }

  //

  isShow.value = true;
});

function validatePhoneNumber(phone: string | null | undefined): boolean {
  if (!phone) {
    // Если phone пустой, null или undefined, возвращаем false
    return false;
  }

  // Регулярное выражение для проверки номера телефона
  const phoneRegex =
    /^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/;

  // Проверяем соответствие регулярному выражению
  return phoneRegex.test(phone);
}

const isPhoneValid = (phone: string, isRequired = true) => {
  if (!isRequired && !formData.value.email) return true;

  return validatePhoneNumber(phone);
};

const isEmailValid = (email: string, isRequired = false) => {
  if (!isRequired && !email) return true;

  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  } else {
    return false;
  }
};

const updateForm = async () => {
  await nextTick();

  // const grtfbg = formRef.value.getValidationComponents();
  // formRef.value.validate();

  // r

  // if (formType.value !== 'callback') emailRef.value.validate();

  // const nameHasError = nameRef.value.hasError;
  // const phoneHasError = phoneRef.value.hasError;
  // const emailHasError = emailRef.value.hasError;

  // debugger;

  // if (
  //   nameRef.value.hasError ||
  //   phoneRef.value.hasError ||
  //   emailRef.value.hasError
  // ) {
  //   isFormValid.value = false;
  // } else {
  //   isFormValid.value = true;
  // }
};

const send = () => {
  // if (!isFormValid.value) return;

  if (formData.value.email === '') {
    delete formData.value.email;
  }

  formData.value
    .save()
    .then(() => {
      isShow.value = false;
      eventBus.emit('OPEN_SUCCESS_DIALOG');
    })
    .catch((error: Error) => console.error(error));
};

// TODO useDialogPluginComponent
// TODO useFormChild
</script>

<template lang="pug">
q-dialog.dialog(
  v-model="isShow"
  persistent
  :maximized="$q.screen.lt.sm")
  q-card.dialog__card(style="border-radius: 1.25rem").w-full.max-w-420px.relative.row.items-center.rounded-5
    BtnDialogClosePopup().z-top
    .dialog__card__container().col-12
      q-card-section
        q-toolbar
          q-toolbar-title(style="padding: 0;").text-center
            .title().alegreya.text-40px.font-400.lg-text-40px.leading-none
              | {{ formData.title }}
            .subtitle().text-18px.mt-16px Мы рады вашим идеям
      q-card-section
        q-form.dialog__form(
          ref="formRef"
          @validation-success="isFormValid = true"
          @validation-error="isFormValid = false"
          @submit="send").q-col-gutter-y-lg
          //
          //- InputWrap(
          //-   ref="nameRef"
          //-   v-model="formData.name"
          //-   label="ФИО"
          //-   :rules="[val => !!val || 'Пожалуйста заполните']"
          //-   outlined
          //-   rounded="8"
          //-   @update:model-value="updateForm"
          //- )
          //
          q-input(
            ref="nameRef"
            v-model.trim="formData.name"
            label="Имя *"
            lazy-rules
            :rules="[val => !!val || 'Пожалуйста заполните']"
            outlined
            @update:model-value="updateForm")
            //- template(#prepend)
              q-icon(name="account_circle")

          q-input(
            ref="phoneRef"
            v-model.trim="formData.phone"
            label="Телефон *"
            type="tel"
            mask="+7 ### ### ## ##"
            fill-mask
            lazy-rules
            :rules="[val => !!val || 'Пожалуйста заполните', val => isPhoneValid(val) || 'Неверный формат']"
            outlined
            @update:model-value="updateForm")
              //- template(#prepend)
                q-icon(name="phone_android")
          //- .field(v-if="formType !== 'callback'")
            .label Email
          q-input(
              ref="emailRef"
              v-model.trim="formData.email"
              label="Email"
              type="email"
              placeholder="name@mail.ru"
              lazy-rules
              :rules="[val => isEmailValid(val) || 'Неверный формат']"
              outlined
              @update:model-value="updateForm")
              //- template(#prepend)
                q-icon(name="email")
        //
        q-card-actions.dialog__card__btn-actions(align="center").q-px-none.mt-20px
          BtnL.dialog__form__send-btn(
            label="Отправить"
            @click="formRef?.submit").rounded-12
            //- :disabled="!isFormValid"
        q-card-actions.dialog__privacy-policy(align="center").q-pa-md.text-center
          div().w-full.text-gray нажимая на кнопку вы соглашаетесь
          br
          a(
            href="/Политика_конфиденциальности.pdf"
            target="_blank"
            style="text-decoration: none;").text-primary.cursor-pointer с политикой конфидециальности
</template>
