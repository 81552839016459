export const menuItems = [
  {
    label: 'Главная',
    name: 'home',
    path: '/',
    icon: 'home',
  },
  {
    label: 'Проекты',
    name: 'projects',
    path: '/projects',
  },
  // {
  //   label: 'Услуги',
  //   name: 'services',
  //   path: '/services',
  // },
  {
    label: 'Блог',
    name: 'blog',
    path: '/blog',
  },
  // {
  //   label: 'Связаться',
  //   name: 'contacts',
  //   path: '/contacts',
  // },
];
