<script setup lang="ts">
const eventBus = inject('bus') as EventBus;

const isShow = ref();

eventBus.on('OPEN_SUCCESS_DIALOG', () => {
  isShow.value = true;
});
</script>

<template lang="pug">
q-dialog.success__dialog.dialog(
  id="success"
  v-model="isShow")
  q-card(style="border-radius: 1.25rem").w-full.max-w-420px.relative.row.items-center
    BtnDialogClosePopup
    .dialog__card__container().col.q-px-md
      q-card-section().text-center
        .title().alegreya.text-40px.lg-text-40px.leading-none
          span Спасибо!
          br
          span Данные отправлены
        .subtitle().text-18px.mt-16px Мы скоро свяжется с вами
      q-card-actions(align="center").q-ma-lg
        BtnL(
          v-close-popup
          label="Хорошо"
          color="primary")
</template>
